<template>
  <div>
    <XyzTransitionGroup
      appear
      class="item-group"
      xyz="fade left-3"
    >
      <b-card key="1">
        <XyzTransition
          appear
          xyz="fade up-3 delay-7"
        >
          <b-row
            class="mb-1"
            align-h="between"
          >
            <b-col
              sm="6"
              lg="3"
              class="mb-1 pr-sm-50"
            >
              <b-form-input
                v-model="filterScheme.search"
                placeholder="Cari scheme"
                debounce="400"
              />
            </b-col>
            <b-col
              sm="6"
              lg="3"
              class="mb-1 pl-sm-50"
            >
              <v-select
                v-model="filterScheme.status"
                class="w-100"
                :options="[{ name: 'Tidak Aktif', value: 0 }, { name: 'Aktif', value: 1 }]"
                label="name"
                :clearable="false"
                placeholder="Filter status"
              />
            </b-col>
            <b-col
              sm="12"
              lg="3"
              class="ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="!permission.schemeAdd"
                :to="{ name: 'addscheme' }"
                variant="primary"
                class="float-right btn-block mb-1"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
                Schema Baru
              </b-button>
            </b-col>
            <b-col
              v-if="filterMode"
              cols="12"
              class="mb-50"
            >
              <b-badge
                variant="primary"
                class="align-self-center mr-50 cursor-pointer"
                @click="resetFilter"
              >
                Reset Filter
                <feather-icon
                  icon="XIcon"
                />
              </b-badge>
            </b-col>
          </b-row>
        </XyzTransition>
        <XyzTransition
          appear
          xyz="fade down-3 delay-5"
        >
          <b-overlay :show="loadingScheme">
            <scheme-table
              :key="filteredData.length"
              :data="filteredData"
              :count="filteredData.length"
              :per-page="perPage"
              @pageChanged="pageChanged"
              @perPageChanged="perPageChanged"
            />
          </b-overlay>
        </XyzTransition>
      </b-card>
    </XyzTransitionGroup>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormInput, BBadge, BOverlay,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import vSelect from 'vue-select'
import Fuse from 'fuse.js'
import SchemeTable from './SchemeTable.vue'

const { mapFields } = createHelpers({
  getterType: 'getSchemeField',
  mutationType: 'updateSchemeField',
})

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BBadge,
    SchemeTable,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      loading: true,
      perPage: 25,
      currentPage: 0,
    }
  },
  computed: {
    ...mapFields([
      'filterScheme',
      'loadingScheme',
    ]),
    ...mapGetters({
      scheme: 'getSchemeList',
      schemeCount: 'getSchemeCount',
    }),
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    filterMode() {
      if (this.filterScheme.search) return true
      if (this.filterScheme.status.value !== 1) return true
      return false
    },
    filteredData() {
      let schemeList = this.scheme
      if (this.filterScheme.status) {
        schemeList = schemeList.filter(el => el.status === this.filterScheme.status.value)
      }

      if (this.filterScheme.search) {
        const fuse = new Fuse(schemeList, {
          keys: ['name', 'notes', 'color.ind_name', 'color.eng_name', 'color.id_ral', 'color.hex_code'],
          shouldSort: true,
          findAllMatches: true,
        })
        schemeList = fuse.search(this.filterScheme.search).map(({ item }) => item).slice(0, 10)
      }
      return schemeList
    },
  },
  mounted() {
    if (this.scheme.length < 1) {
      setTimeout(() => {
        this.getScheme()
      }, 1000)
    }
  },
  methods: {
    ...mapActions(['getScheme']),
    pageChanged(data) {
      this.currentPage = data
    },
    perPageChanged(data) {
      this.perPage = data
    },
    resetFilter() {
      this.filterScheme = {
        search: '',
        status: { name: 'Aktif', value: 1 },
        perPage: 25,
        offset: 1,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
