<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage:perPage
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <router-link :to="{ name: 'scheme-detail', params: {id: props.row.id.toString()} }">
            <h6 class="text-primary m-0">{{ props.row.name }}</h6>
          </router-link>
          <small>{{ props.row.notes }}</small>
        </span>
        <span v-else-if="props.column.field === 'itemCategory'">
          <h6 class="m-0">{{ props.row.itemCategory.name }}</h6>
        </span>
        <span v-else-if="props.column.field === 'type'">
          <h6 class="m-0">{{ props.row.type.name }}</h6>
        </span>
        <span v-else-if="props.column.field === 'min_coat'">
          <h6 v-if="props.row.max_coat"><feather-icon
            class="mr-25"
            icon="ChevronsUpIcon"
          />{{ props.row.max_coat }}</h6>
          <h6 v-if="props.row.min_coat"><feather-icon
            class="mr-25"
            icon="ChevronsDownIcon"
          />{{ props.row.min_coat }}</h6>
        </span>
        <span v-else-if="props.column.field === 'min_dft'">
          <h6 v-if="props.row.max_dft"><feather-icon
            class="mr-25"
            icon="ChevronsUpIcon"
          />{{ props.row.max_dft }}</h6>
          <h6 v-if="props.row.min_dft"><feather-icon
            class="mr-25"
            icon="ChevronsDownIcon"
          />{{ props.row.min_dft }}</h6>
        </span>
        <span v-else-if="props.column.field === 'min_loss'">
          <h6 v-if="props.row.max_loss"><feather-icon
            class="mr-25"
            icon="ChevronsUpIcon"
          />{{ props.row.max_loss }}</h6>
          <h6 v-if="props.row.min_loss"><feather-icon
            class="mr-25"
            icon="ChevronsDownIcon"
          />{{ props.row.min_loss }}</h6>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="props.row.status == 1 ? 'primary' : 'secondary'">{{ props.row.status == 1 ? 'Aktif' : 'Tidak Aktif' }}</b-badge>
        </span>
        <span v-else-if="props.column.field === 'color'">
          <div
            v-if="props.row.color"
            class="d-flex align-items-center"
          >
            <div
              v-b-popover.hover.top="'#'+props.row.color.hex_code"
              class="colorswatch mr-50"
              :style="`background-color: #${props.row.color.hex_code};`"
            />
            <div>
              <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
              <small>{{ props.row.color.id_ral }}</small>
            </div>
          </div>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              offset="-100"
              boundary="window"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'scheme-detail', params: {id: props.row.id.toString()}}"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50 text-primary"
                />
                <span class="text-primary">Lihat Scheme</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                :disabled="!permission.schemeEdit"
                @click="permission.schemeEdit ? $router.push({ name: 'scheme-edit', params: {id: props.row.id.toString()} }) : $router.push('/unauthorized')"
              >

                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                  :class="permission.schemeEdit ? 'text-warning' : 'text-muted'"
                />
                <span :class="permission.schemeEdit ? 'text-warning' : 'text-muted'">Edit Scheme</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!permission.schemeEdit"
                @click="permission.schemeEdit ? $router.push({ name: 'scheme-copy', params: {id: props.row.id.toString()}}) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
                  :class="permission.schemeEdit ? 'text-info' : 'text-muted'"
                />
                <span :class="permission.schemeEdit ? 'text-info' : 'text-muted'">Copy Scheme</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-show="props.row.status === 1 "
                :disabled="!permission.schemeDelete"
                @click="permission.schemeDelete ? confirmDelete(props.row.id) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  :class="permission.schemeDelete ? 'text-danger' : 'text-muted'"
                />
                <span :class="permission.schemeDelete ? 'text-danger' : 'text-muted'">Hapus Scheme</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-show="props.row.status === 0 "
                :disabled="!permission.schemeDelete"
                @click="permission.schemeDelete ? confirmRestore(props.row.id) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="ShareIcon"
                  class="mr-50"
                  :class="permission.schemeDelete ? 'text-danger' : 'text-muted'"
                />
                <span
                  :class="permission.schemeDelete ? 'text-danger' : 'text-muted'"
                >Restore Scheme</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="limitPage"
              :options="perPageOptions"
              class="mx-1"
              @input="handlePerPage"
            />
            <span class="text-nowrap "> dari total {{ rows.length }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="offset"
              :total-rows="props.total"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:offset})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormSelect, BPagination, BDropdown, BDropdownItem, BDropdownDivider, VBPopover, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getSchemeField',
  mutationType: 'updateSchemeField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    VueGoodTable,
    EmptyStateTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      limitPage: 25,
      perPageOptions: [25, 50, 100, 200],
      columns: [
        {
          field: 'name',
          label: 'Nama',
          sortable: true,
          tdClass: 'w-vgt-260',
        },
        {
          field: 'color',
          label: 'Warna',
          sortable: false,
          width: '200px',
        },
        {
          field: 'jum_item',
          label: 'Jumlah Item',
          sortable: true,
          type: 'number',
        },
        {
          field: 'min_coat',
          label: 'Coat',
          sortable: false,
        },
        {
          field: 'min_dft',
          label: 'DFT',
          sortable: false,
        },
        {
          field: 'min_loss',
          label: 'Loss',
          sortable: false,
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
    ...mapFields({
      offset: 'filterScheme.offset',
    }),
    rows() {
      return this.data
    },
  },
  mounted() {
    this.$refs.vgt.currentPage = this.offset
  },
  methods: {
    ...mapActions(['deleteProject']),
    handleInput(value) {
      this.$emit('pageChanged', value)
    },
    handlePerPage(value) {
      this.$emit('perPageChanged', value)
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Hapus Data Scheme?',
        text: 'Konfirmasi jika anda ingin menghapus data scheme',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('deleteScheme', id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data scheme',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.$store.dispatch('getScheme')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data scheme',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    confirmRestore(id) {
      console.log(id)
      this.$swal({
        title: 'Restore Data Scheme?',
        text: 'Konfirmasi jika anda ingin restore data scheme',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('restoreScheme', id).then(() => {
            this.$store.dispatch('getScheme')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil restore data scheme',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal restore data scheme',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.colorswatch {
  height: 36px;
  width: 36px;
  border-radius: 4px;
}
</style>
